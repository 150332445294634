$slidePaddingMobile: 7px;
$slidePaddingDesktop: 8px;

.layout-carousel-title {
    margin-bottom: rem(24px);
    text-transform: uppercase;
}

.layout-carousel {
    &-container {
        &.grid-mobile {
            @include media-breakpoint-down(md) {
                margin-left: 0;
                padding-right: rem(15px);
                padding-left: rem(15px);
            }

            .layout-carousel {
                &:not(.slick-initialized) {
                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;
                        justify-content: center;
                        margin: 0 rem(-6px);
                    }

                    .slide {
                        @include media-breakpoint-down(xs) {
                            padding: 0 rem(6px);
                            display: none;
                        }

                        &:nth-child(-n+8) {
                            @include media-breakpoint-down(xs) {
                                display: block;
                            }
                        }
                    }
                }

                .feature {
                    img {
                        width: 100%;
                    }

                    .small-standalone-link {
                        font-family: $font-avenir-next-regular;
                    }
                }
            }
        }

        .carousel-title {
            @include media-breakpoint-down(sm) {
                max-width: 72%;
            }
        }
    }

    &:not(.slick-initialized) {
        display: flex;
        overflow: hidden;

        > * {
            flex-shrink: 0;
            width: rem(262px);
            padding: 0 rem($slidePaddingMobile);

            @include media-breakpoint-up(lg) {
                width: rem(316px);
                padding: 0 rem($slidePaddingDesktop);
            }
        }
    }

    .slick-list {
        margin-left: rem(-$slidePaddingMobile);
        margin-right: rem(-15px);

        @include media-breakpoint-up(lg) {
            margin-left: rem(-$slidePaddingDesktop);
        }

        @include media-breakpoint-up(xl) {
            margin-right: rem(-40px);
        }
    }

    .slick-arrow {
        top: rem(-24px);
        left: unset;
        transform: translateY(-100%);
        width: rem(24px);
        height: rem(24px);
        background-color: transparent;

        &,
        &:hover {
            &::before {
                display: none;
            }

            &::after {
                background-image: url(../../svg-icons/arrow-forward.svg);
            }
        }

        &.slick-disabled {
            opacity: 0.4;
        }
    }

    .slick-prev {
        right: rem(36px);

        &::after {
            transform: rotate(180deg);
        }
    }

    .slick-next {
        right: 0;
    }

    .slick-slide {
        padding: 0 rem($slidePaddingMobile);

        @include media-breakpoint-up(lg) {
            padding: 0 rem($slidePaddingDesktop);
        }

        > div {
            position: relative;
        }
    }
}

.hide-wishlist {
    .wishlistTile {
        display: none !important;
    }
}

.hide-badge {
    .badge-text-tile-sale,
    .badge-text-tile-evergreen,
    .badge-text-tile-evergreen-bk,
    .badge-text-tile-sale {
        display: none !important;
    }
}

.hide-name {
    .product-brand-name,
    .names-container {
        display: none !important;
    }
}

.hide-price {
    .price-container {
        display: none !important;
    }
}

.hide-button {
    .link {
        display: none !important;
    }
}

.lm-einstein-carousel-container {
    &.empty {
        .layout-carousel-container {
            display: none;
        }
    }

    .slick-slide {
        width: rem(246px);

        @include media-breakpoint-up(sm) {
            width: rem(300px);
        }
    }

    .slick-list {
        padding-right: 40%;

        @include media-breakpoint-up(sm) {
            padding-right: 11%;
        }
    }
}
